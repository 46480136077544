import {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import nth from 'lodash/nth';

import {useBreakPoint, useDestructiveDialog, useFeatureToggle, useModal} from '@hooks';

import {
  type MeetingRoom,
  getBuildingByCheckInOrWorkspaceReservation,
  getFloorsWithMapByBuildingId,
  getUser,
  loadAllMeetingRooms,
  withAsyncThunkErrorHandling,
  getFloorById,
  getShouldShowHereAndNowConnectYourCalendarFullScreen,
  getDisplayCalendarSyncTile,
  type Area,
  getIsLoadingCalendarState,
  getIsLoadingUserCheckIn,
  getIsLoadingWorkspaceReservations,
  getIsOccupancyEnabled,
  loadWorkspaceOccupancy,
  getWorkspaceOccupancyLoadingStatusForBuildingId,
  getAllMeetingRoomSuggestions,
  getAppLanguage,
  getFloorsByBuildingId,
  getAvailableDeskAmenityDataByBuildingId,
  getAvailableActivityTypeDataByBuildingId,
  getAvailableDeskAmenitiesForBuilding,
  getAvailableActivityTypesForBuilding,
  getAreasWithSensorData,
  AreaWithSensorInfo,
  getDefaultBuilding,
  getAllBuildings,
  getBuildingById,
  getHasMeetingPreconditions,
  getAllMeetingRoomSuggestionsUnfiltered,
  getMapDataByFloorId,
  useAppDispatch,
  useAppSelector,
  getMeetingRoomSuggestionByBuildingId,
} from '@lib/store';

import {addMinutes, parseISO, startOfDay, startOfMinute, differenceInMinutes} from 'date-fns';

import {DEFAULT_NEW_EVENT_DURATION as DURATION, MINUTES_TRESHOLD_FOR_STALE_DATA} from '@constants';
import {Div, FlexCol, FlexRow, H3} from '@quarks';
import {
  HaNControls,
  HaNMaps,
  HaNModalContainer,
  HaNFilters,
  HaNHorizontalListContent,
  HaNVerticalListContent,
  isMeetingRoom,
  HaNCalendarSyncTile,
  HaNCalendarSync,
  SingleSelectCard,
  HaNExploreSearchBar,
} from '@organisms';
import {Canvas, Heading, HereAndNowLayout} from '@templates';

import {AreaEventTarget, RoomEventTarget} from '../../../../../../submodules/map/mapiq-map/EventTarget';
import {
  ControlsRow,
  HaNPageHeader,
  MapWrapper,
  RoomListGridArea,
  StyledIconNav,
  StyledNavLink,
  StyledNavLinkWithIcon,
  StyledTabs,
  Title,
} from './styles';
import {IconButton, NoMapPlaceholder, SwiperRefType} from '@molecules';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Loader} from '@atoms';
import {
  constructHereAndNowRoomsFilter,
  constructHereAndNowWorkspacesFilter,
  useFilterData,
  useMeetingRoomAmenities,
} from '@lib/logic';
import {getDeskAmenityIcon, getRoomEquipementIcon} from '@utils';
import {RenderModeToggle} from 'src/components/molecules/MapView/RenderModeToggle/RenderModeToggle';
import {useExploreState} from './useExploreState';
import {FacilityResource} from '@lib/store/src/data/buildingResources';
import uniqBy from 'lodash/uniqBy';
import {trackEvent, trackPageComponentedRendered} from '@lib/infrastructure';

const initialStartDateTime = startOfMinute(new Date()).toISOString();
const initialEndDateTime = addMinutes(parseISO(initialStartDateTime), 30).toISOString();

export const HaN_Previous_Tab = 'HaN_Previous_Tab';

export const HereAndNow = (props: {tab: string}) => {
  // * UI
  const ref = useRef<SwiperRefType | null>(null);
  const {t} = useTranslation();
  const breakPoint = useBreakPoint();
  const {addPages, openModal} = useModal();
  const {destructiveDialog} = useDestructiveDialog();
  const tab = props.tab;

  // * URL INITIALIZATION
  const [searchParams, setSearchParams] = useSearchParams();
  const exploreQuery = searchParams.get('q') ?? '';
  const linkedBuildingId = searchParams.get('buildingId');
  // react router tells us to not perform navigation in a render function
  // so this needs to be in an effect
  useEffect(
    function removeURLParams() {
      if (searchParams.has('q') || searchParams.has('buildingId')) {
        searchParams.delete('q');
        searchParams.delete('buildingId');
        setSearchParams(searchParams, {replace: true});
      }
    },
    [searchParams, setSearchParams],
  );

  // * FILTERS
  const [{startDateTime, endDateTime}, setRoomDateTimes] = useState({
    startDateTime: initialStartDateTime,
    endDateTime: initialEndDateTime,
  });

  const lastOccupancyLoadForBuildingId = useRef<Record<string, Date | undefined>>({});

  // * STATE
  const [roomInformationOpen, setRoomInformationOpen] = useState(false);
  const [warnBeforeOpeningNewRoom, setWarnBeforeOpeningNewRoom] = useState(false);
  const [hoverRoomId, setHoverRoomId] = useState<string | null>(null);
  const [selectedObject, setSelectedObject] = useState<MeetingRoom | AreaWithSensorInfo | FacilityResource | null>(
    null,
  );
  const [selectedObjectForMap, setSelectedObjectForMap] = useState<
    MeetingRoom | AreaWithSensorInfo | FacilityResource | null
  >(null);

  // * REDUX SELECTORS
  const dispatch = useAppDispatch();
  const building = useAppSelector((state) =>
    getBuildingByCheckInOrWorkspaceReservation(state, startOfDay(new Date()).toISOString()),
  );
  const allBuildings = useAppSelector(getAllBuildings);
  const allBuildingIds = allBuildings.map((b) => b.id);
  const {id: loggedInUserId} = useAppSelector(getUser);
  const sessionBuildingIdKey = useMemo(() => `${loggedInUserId}_buildingId`, [loggedInUserId]);
  const sessionBuildingId = linkedBuildingId ?? sessionStorage.getItem(sessionBuildingIdKey) ?? '';
  const {id: reservationBuildingId = ''} = building ?? {};
  const initBuildingId =
    allBuildingIds.length && allBuildingIds.includes(sessionBuildingId) ? sessionBuildingId : reservationBuildingId;
  const navigate = useNavigate();

  const [buildingId, setBuildingId] = useState(initBuildingId);
  const minutesThresholdForStaleData = 5;
  const showCalendarConsentMessageNoOccupancy = useAppSelector(getShouldShowHereAndNowConnectYourCalendarFullScreen);
  const displayCalendarSyncTile = useAppSelector(getDisplayCalendarSyncTile);
  const {
    WorkspacesOnHereAndNow: isWorkspacesOnHereAndNowEnabled,
    HybridMeetingsCalendarView: isHybridMeetingsCalendarViewEnabled,
  } = useFeatureToggle();
  const calendarLoadingStatus = useAppSelector(getIsLoadingCalendarState);
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const hasMeetingPreconditions = useAppSelector(getHasMeetingPreconditions);
  const floors = useAppSelector((state) => getFloorsByBuildingId(state, buildingId));
  const appLanguage = useAppSelector(getAppLanguage);
  const loadingCalendar = calendarLoadingStatus === 'Loading';
  const occupancyLoadingStatus = useAppSelector((state) =>
    getWorkspaceOccupancyLoadingStatusForBuildingId(state, buildingId),
  );
  const isLoadingSensors = occupancyLoadingStatus === 'loading' && isOccupancyEnabled;
  const {status: meetingRoomLoadingStatus} =
    useAppSelector((state) => getMeetingRoomSuggestionByBuildingId(state, buildingId)) ?? {};
  const isLoadingUserCheckIn = useAppSelector(getIsLoadingUserCheckIn);
  const isLoadingWorkspaceReservations = useAppSelector(getIsLoadingWorkspaceReservations);
  const isLoadingBuilding = isLoadingUserCheckIn || isLoadingWorkspaceReservations;
  const loadingMeetingRooms = meetingRoomLoadingStatus === 'loading';
  const isLoading = loadingCalendar || loadingMeetingRooms || isLoadingSensors;
  const areasWithSensorData = useAppSelector((state) => getAreasWithSensorData(state, buildingId, t));
  const allRooms = useAppSelector((state) => getAllMeetingRoomSuggestions(state, buildingId));
  const {availableDeskAmenities, status: deskAmenitiesLoadingStatus} = useAppSelector(
    getAvailableDeskAmenityDataByBuildingId(buildingId),
  );
  const allRoomsWithAnyScore = useAppSelector((state) => getAllMeetingRoomSuggestionsUnfiltered(state, buildingId));
  const {availableActivityTypes, status: activityTypesLoadingStatus} = useAppSelector(
    getAvailableActivityTypeDataByBuildingId(buildingId),
  );
  const defaultBuilding = useAppSelector(getDefaultBuilding);

  const buildingName = useAppSelector((state) => getBuildingById(state, buildingId))?.name || t('translation:Booked');

  // FILTERS

  const floorsWithRooms = uniqBy(
    allRooms.map((room) => ({id: room.floorId, name: room.floorName})),
    'id',
  );

  const floorsWithWorkspaces = floors.filter((floor) =>
    areasWithSensorData.map((area) => area.floorId).includes(floor.id),
  );
  const {meetingRoomAmenitiesByBuilding} = useMeetingRoomAmenities();
  const meetingRoomAmenitiesForBuilding = meetingRoomAmenitiesByBuilding[buildingId];

  const roomFilters = useMemo(
    () =>
      constructHereAndNowRoomsFilter(
        floorsWithRooms,
        hasMeetingPreconditions,
        t,
        appLanguage,
        meetingRoomAmenitiesForBuilding?.activities ?? [],
        meetingRoomAmenitiesForBuilding?.equipment ?? [],
        getRoomEquipementIcon,
      ),
    [
      appLanguage,
      floorsWithRooms,
      hasMeetingPreconditions,
      meetingRoomAmenitiesForBuilding?.activities,
      meetingRoomAmenitiesForBuilding?.equipment,
      t,
    ],
  );

  const areaFilters = useMemo(
    () =>
      constructHereAndNowWorkspacesFilter(
        floorsWithWorkspaces,
        availableDeskAmenities,
        availableActivityTypes,
        getDeskAmenityIcon,
        isOccupancyEnabled,
        t,
      ),
    [availableActivityTypes, availableDeskAmenities, floorsWithWorkspaces, isOccupancyEnabled, t],
  );

  // Current set-up forces us to do all these things in the parent component...
  const exploreState = useExploreState(buildingId, exploreQuery);

  const {filteredData: rooms, selectedFilters: roomSelectedFilters} = useFilterData(
    'HereAndNow_Rooms',
    buildingId,
    allRooms,
    roomFilters,
    'and',
    loadingMeetingRooms,
  );

  const {filteredData: areas} = useFilterData(
    'HereAndNow_Workspaces',
    buildingId,
    areasWithSensorData,
    areaFilters,
    'and',
    isLoadingSensors,
  );

  const mapFloorId =
    tab === 'explore'
      ? exploreState.floorId
      : selectedObjectForMap?.floorId
      ? selectedObjectForMap.floorId
      : tab === 'rooms'
      ? rooms[0]?.floorId || null
      : areas[0]?.floorId || null;

  const mapData = useAppSelector(getMapDataByFloorId(buildingId, mapFloorId || ''));
  const floorsWithMap = useAppSelector((state) => getFloorsWithMapByBuildingId(state, buildingId));
  const mapFloor = useAppSelector((state) => getFloorById(state, mapFloorId || ''));

  const [roomListVerticalOpen, setRoomListVerticalOpen] = useState(floorsWithMap.length ? false : true);
  // * CONSTS

  const duration = Number(roomSelectedFilters['SECTION_ID_DURATION']?.[0]) || DURATION;
  const displayControlsRow = mapFloor && !showCalendarConsentMessageNoOccupancy;

  const paddingLeftPixels = breakPoint === 'xLarge' ? 420 : 0; // Width of a modal
  const paddingBottomPixels = breakPoint === 'small' ? 100 : 0; // Height of a horizontal rooms list
  const areFiltersTransparent = breakPoint === 'small' && roomListVerticalOpen;

  const viewportRestrictions = useMemo(
    () => ({
      paddingTopPixels: 80, // Filters height and padding
      paddingRightPixels: 0,
      paddingBottomPixels: paddingBottomPixels,
      paddingLeftPixels,
    }),
    [paddingLeftPixels, paddingBottomPixels],
  );

  const objectToSelectInitially = tab === 'workspaces' ? areas[0] ?? null : rooms[0] ?? null;
  const isInMobielView = breakPoint === 'small';
  const hasInitallySelectedObject = selectedObject !== null;

  // * FUNCS

  const loadHereAndNowRooms = useCallback(() => {
    const loadRooms = async () => {
      await dispatch(
        withAsyncThunkErrorHandling(() =>
          loadAllMeetingRooms({
            startDateTime: newStartDateTime,
            endDateTime: newEndDateTime,
            buildingId: buildingId,
            internalAttendeeIds: [loggedInUserId],
            totalAttendees: 1,
          }),
        ),
      );
    };

    // Check required feature flags
    if (!(isOccupancyEnabled || isHybridMeetingsCalendarViewEnabled)) return;

    const newStartDateTime = startOfMinute(new Date()).toISOString();
    const newEndDateTime = addMinutes(parseISO(newStartDateTime), duration).toISOString();

    setRoomDateTimes({startDateTime: newStartDateTime, endDateTime: newEndDateTime});

    if (buildingId && duration && loggedInUserId) {
      loadRooms();
    }
  }, [buildingId, dispatch, duration, loggedInUserId, isOccupancyEnabled, isHybridMeetingsCalendarViewEnabled]);

  const fetchOccupancySensorData = useCallback(() => {
    if (!isLoadingBuilding && buildingId) {
      dispatch(withAsyncThunkErrorHandling(() => loadWorkspaceOccupancy(buildingId)));

      lastOccupancyLoadForBuildingId.current[buildingId] = startOfMinute(new Date());
    }
  }, [buildingId, dispatch, isLoadingBuilding]);

  const refreshRoomsData = () => {
    if (buildingId && loggedInUserId) {
      const now = startOfMinute(new Date());
      if (differenceInMinutes(now, parseISO(startDateTime)) >= MINUTES_TRESHOLD_FOR_STALE_DATA) {
        loadHereAndNowRooms();
      }
    }
  };

  const toggleListOrMapView = () => {
    if (!roomListVerticalOpen) {
      ref.current?.scrollToSlide(0);
    }
    setRoomListVerticalOpen(!roomListVerticalOpen);
  };

  const openObjectInformation = async (entity: MeetingRoom | AreaWithSensorInfo | FacilityResource | undefined) => {
    // Some of the areas have capacity of 0 which do not get fetched
    // because of that they will not be found and we need not handle possible undefined value

    if (!entity) {
      setSelectedObjectForMap(null);
      setSelectedObject(null);
      return;
    } else if (isMeetingRoom(entity)) {
      if (warnBeforeOpeningNewRoom) {
        const confirmation = await destructiveDialog(
          t('meeting:DiscardEventChangesTitle'),
          t('meeting:DiscardEventChangesMessage'),
          'warning',
          t('meeting:DiscardEventChangesConfirm'),
        );
        if (confirmation) {
          setSelectedObjectForMap(entity);
          setSelectedObject(entity);
          setRoomInformationOpen(true);
        }
      } else {
        setSelectedObjectForMap(entity);
        setSelectedObject(entity);
        setRoomInformationOpen(true);
      }
    } else {
      setSelectedObject(entity);
      setSelectedObjectForMap(entity);
      setRoomInformationOpen(true);
    }
  };

  const closeDetailsModal = () => {
    setRoomInformationOpen(false);
    setSelectedObject(null);
    if (exploreState.selectedResource) {
      exploreState.setSelectedResource(null);
    }
  };

  const handleOnBackExplore = () => {
    if (exploreState.rawQuery.length) {
      exploreState.setRawQuery('');
    } else {
      const previousTab = sessionStorage.getItem(HaN_Previous_Tab) ?? '/now/rooms';
      setRoomInformationOpen(false);
      setSelectedObject(null);
      setSelectedObjectForMap(null);
      navigate(previousTab);
    }
  };

  const resetListState = () => {
    if (breakPoint === 'small') {
      ref.current?.scrollToSlide(0);
    }
  };

  const resetEntityState = useCallback(
    (tabValue?: string) => {
      if (breakPoint === 'small') {
        const objectToSelect = tabValue === 'rooms' ? rooms[0] : areas[0];
        setRoomInformationOpen(false);
        setSelectedObject(objectToSelect);
        setSelectedObjectForMap(objectToSelect);
      } else {
        setRoomInformationOpen(false);
        setSelectedObject(null);
        setSelectedObjectForMap(null);
      }
    },
    [areas, breakPoint, rooms],
  );

  const handleRoomClick = (e: RoomEventTarget | AreaEventTarget) => {
    if (breakPoint === 'small') {
      if (e.type === 'room') {
        ref?.current?.scrollToSlide(rooms.findIndex((room) => room.id === e.roomId));
      } else {
        ref?.current?.scrollToSlide(areas.findIndex((area) => area.id === e.areaId));
      }
    } else {
      if (e.roomId) {
        const clickedRoom = rooms.find((room) => room.id === e.roomId);
        openObjectInformation(clickedRoom);
      } else if (e.areaId) {
        const clickedArea = areas.find((area) => area.id === e.areaId);
        openObjectInformation(clickedArea);
      }
    }
  };

  const handleOnSlideChange = (visibleObject: MeetingRoom | AreaWithSensorInfo) => {
    setSelectedObject(visibleObject);
    setSelectedObjectForMap(visibleObject);
  };

  const handleBookNow = () => {
    if (selectedObject !== null) {
      if (isMeetingRoom(selectedObject) && breakPoint === 'small') {
        const currentIndex = rooms.indexOf(selectedObject!);
        const nextRoomValue = nth(rooms, currentIndex + 1) ?? nth(rooms, currentIndex - 1) ?? null;
        setSelectedObjectForMap(nextRoomValue);
        setSelectedObject(nextRoomValue);
      }
    }
  };

  const handleBuildingSelectClick = () => {
    addPages([
      <SingleSelectCard
        defaultId={defaultBuilding?.id || buildingId}
        preselectedId={buildingId}
        headerTitle={t('screen:MeetingBuildingSelection')}
        entities={allBuildings.reduce(
          (buildings, currentBuilding) => {
            if (currentBuilding.id !== defaultBuilding?.id) {
              buildings.push({
                id: currentBuilding?.id ?? '',
                label: currentBuilding?.name ?? '',
              });
            }
            return buildings;
          },
          [{id: defaultBuilding?.id || '', label: defaultBuilding?.name || ''}],
        )}
        onSelect={(selectedBuildingId: string) => {
          trackEvent('HereAndNow__ChangeBuilding', {id: selectedBuildingId});
          setBuildingId(selectedBuildingId);
          closeDetailsModal();
        }}
      />,
    ]);
    openModal();
  };

  // * SIDEEFFECTS

  useEffect(() => {
    switch (tab) {
      case 'rooms':
        trackPageComponentedRendered('hereAndNowRooms', !isLoading);
        break;
      case 'workspaces':
        trackPageComponentedRendered('hereAndNowWorkspaces', !isLoading);
        break;
      case 'explore':
        trackPageComponentedRendered('hereAndNowExplore', !isLoading);
        break;
    }
  }, [dispatch, isLoading, tab]);

  useEffect(() => {
    if (tab === 'explore') {
      if (exploreState.selectedResource?.type === 'room') {
        const selectedRoom = allRoomsWithAnyScore.find((room) => room.id === exploreState.selectedResource?.id);
        if (selectedRoom) {
          openObjectInformation(selectedRoom);
        }
      } else if (exploreState.selectedResource?.type === 'area') {
        const selectedWorkSpace = areas.find((area) => area.id === exploreState.selectedResource?.id);
        if (selectedWorkSpace) {
          openObjectInformation(selectedWorkSpace);
        }
      } else if (exploreState.selectedResource?.type === 'facility') {
        openObjectInformation(exploreState.selectedResource);
      } else if (!exploreState.selectedResource && selectedObject) {
        openObjectInformation(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tab,
    exploreState.selectedResource?.id,
    exploreState.selectedResource?.type,
    rooms,
    exploreState.selectedResource,
    selectedObject,
    areas,
  ]);

  useEffect(() => {
    // For the building selector we want to preserve the selection
    // This particular implementation with session storage is a one off solution.
    // We are checking for the string not to be empty since default redux selector value is ''
    if (!buildingId && reservationBuildingId) {
      setBuildingId(reservationBuildingId);
    }
    sessionStorage.setItem(sessionBuildingIdKey, buildingId);
  }, [buildingId, reservationBuildingId, sessionBuildingIdKey]);

  useEffect(() => {
    if (isLoadingBuilding || !buildingId) return;

    if (deskAmenitiesLoadingStatus === 'NotLoaded') {
      dispatch(withAsyncThunkErrorHandling(() => getAvailableDeskAmenitiesForBuilding({buildingId})));
    }

    if (activityTypesLoadingStatus === 'NotLoaded') {
      dispatch(withAsyncThunkErrorHandling(() => getAvailableActivityTypesForBuilding({buildingId})));
    }
  }, [activityTypesLoadingStatus, buildingId, deskAmenitiesLoadingStatus, dispatch, isLoadingBuilding]);

  useEffect(() => {
    const correctTab = tab === 'workspaces' || tab === 'rooms';
    function matchObjecAndTabType(
      objectType: MeetingRoom['type'] | Area['type'] | FacilityResource['type'],
      tabType: 'workspaces' | 'rooms',
    ) {
      if (
        (objectType === 'area' && tabType === 'workspaces') ||
        (objectType === 'meetingRoom' && tabType === 'rooms')
      ) {
        return true;
      }
      return false;
    }
    if (correctTab && selectedObject && !matchObjecAndTabType(selectedObject.type, tab)) resetEntityState(tab);
  }, [resetEntityState, selectedObject, tab]);

  useEffect(() => {
    // We are currently fetching sensor data even when on rooms tab,
    // looking to optimise this in the future with reduxQuery.

    if (!isOccupancyEnabled || !isWorkspacesOnHereAndNowEnabled) return;

    const now = startOfMinute(new Date());
    const lastLoadForBuilding = lastOccupancyLoadForBuildingId.current[buildingId];

    if (!lastLoadForBuilding || differenceInMinutes(now, lastLoadForBuilding) >= minutesThresholdForStaleData) {
      fetchOccupancySensorData();
    }
  }, [buildingId, isOccupancyEnabled, fetchOccupancySensorData, isWorkspacesOnHereAndNowEnabled]);

  useEffect(() => {
    setRoomListVerticalOpen(floorsWithMap.length ? false : true);
  }, [floorsWithMap]);

  useEffect(() => loadHereAndNowRooms(), [loadHereAndNowRooms]);

  useEffect(() => {
    if (isInMobielView && !hasInitallySelectedObject) {
      // On mobile we need to pre-select a room, but not on larger screens
      setSelectedObject(objectToSelectInitially);
      setSelectedObjectForMap(objectToSelectInitially);
    }
  }, [hasInitallySelectedObject, isInMobielView, objectToSelectInitially]);

  // * UI

  if (isLoading) {
    return (
      <Canvas centerContent>
        <Loader />
      </Canvas>
    );
  }

  if (allBuildingIds.length && !allBuildingIds.includes(sessionBuildingId)) {
    sessionStorage.removeItem(sessionBuildingIdKey);
  }

  return (
    <>
      <Heading>
        <HaNPageHeader
          buttonIcons={
            floorsWithMap.length
              ? [
                  {
                    icon: roomListVerticalOpen ? 'map' : 'weekView',
                    label: roomListVerticalOpen ? t('hereAndNow:Map') : t('hereAndNow:ListOfRooms'),
                    onClick: toggleListOrMapView,
                    showButton: 'mobile',
                  },
                ]
              : undefined
          }
          title={buildingName}
          onBuildingSelect={allBuildings.length > 1 ? handleBuildingSelectClick : undefined}
        />
      </Heading>
      <HereAndNowLayout
        $displayCalendarSyncTile={displayCalendarSyncTile}
        $areWorkspacesEnabled>
        {mapFloorId && mapData.status !== 'Failed' ? (
          <MapWrapper>
            <HaNMaps
              buildingId={buildingId}
              floorId={mapFloorId}
              hoverObjectId={hoverRoomId}
              onObjectClick={handleRoomClick}
              rooms={rooms}
              workspaces={areas}
              selectedObject={selectedObject}
              viewportRestrictions={viewportRestrictions}
              exploreState={exploreState}
              onOutsideClick={closeDetailsModal}
            />
          </MapWrapper>
        ) : (
          <MapWrapper>
            <NoMapPlaceholder />
          </MapWrapper>
        )}
        {displayCalendarSyncTile && (
          <Div
            display="none"
            sm={{display: 'grid', gridArea: 'calendar-sync-tile', zIndex: 1}}>
            <HaNCalendarSyncTile />
          </Div>
        )}
        {tab !== 'explore' && (
          <Div
            gridArea="tabs"
            display="none"
            zIndex={10}
            sm={{
              display: roomListVerticalOpen ? 'none' : 'block',
            }}>
            <StyledTabs>
              {(isHybridMeetingsCalendarViewEnabled || isOccupancyEnabled) && (
                <StyledNavLink
                  onClick={() => {
                    resetEntityState('rooms');
                    refreshRoomsData();
                    sessionStorage.setItem(HaN_Previous_Tab, '/now/rooms');
                  }}
                  $isActive={tab === 'rooms'}
                  to={'/now/rooms'}>
                  {t('hereAndNow:Rooms')}
                </StyledNavLink>
              )}
              {isWorkspacesOnHereAndNowEnabled && (
                <StyledNavLink
                  onClick={() => {
                    resetEntityState('workspaces');
                    sessionStorage.setItem(HaN_Previous_Tab, '/now/workspaces');
                  }}
                  $isActive={tab === 'workspaces'}
                  to={'/now/workspaces'}>
                  {t('hereAndNow:Workspaces')}
                </StyledNavLink>
              )}
              <StyledNavLinkWithIcon to={'/now/explore'}>
                <StyledIconNav
                  onClick={() => resetEntityState()}
                  weight="bold"
                  icon="search"
                  size="20px"
                />
              </StyledNavLinkWithIcon>
            </StyledTabs>
          </Div>
        )}
        {tab === 'explore' && !roomListVerticalOpen && (
          <FlexRow
            gridArea="tabs"
            display="none"
            sm={{display: 'flex'}}
            justifyContent="center"
            alignItems="center">
            <HaNExploreSearchBar
              exploreState={exploreState}
              onBack={handleOnBackExplore}
              showBackButton={!!(isWorkspacesOnHereAndNowEnabled || isHybridMeetingsCalendarViewEnabled)}
            />
          </FlexRow>
        )}

        {!showCalendarConsentMessageNoOccupancy ? (
          <Div
            gridArea="filter"
            zIndex={1}
            overflowX={'hidden'}>
            <HaNFilters
              swiperFilterReset={resetListState}
              areFilterTransparent={areFiltersTransparent}
            />
          </Div>
        ) : null}
        <FlexRow
          gridArea={'controls'}
          alignItems="center">
          {/* Note: we have to swap out the toggle because it does not support reactive namespace changes */}
          {tab === 'rooms' ? (
            <RenderModeToggle mapTypeNamespace={`HereAndNow_rooms`} />
          ) : tab === 'explore' ? (
            <RenderModeToggle mapTypeNamespace={`HereAndNow_explore`} />
          ) : (
            <RenderModeToggle mapTypeNamespace={`HereAndNow_workspaces`} />
          )}
        </FlexRow>

        {displayControlsRow ? (
          <ControlsRow
            alignItems="center"
            gridArea="controls"
            justifyContent="flex-end"
            gap={8}
            zIndex={1}>
            <HaNControls floor={mapFloor?.number} />
          </ControlsRow>
        ) : null}
        <Div
          gridArea={
            showCalendarConsentMessageNoOccupancy
              ? 'controls / room-list-horizontal / room-list-horizontal / room-list-horizontal'
              : 'room-list-horizontal'
          }
          display="none"
          sm={{display: 'grid'}}>
          <HaNHorizontalListContent
            buildingId={buildingId}
            showCalendarConsentMessageNoOccupancy={showCalendarConsentMessageNoOccupancy}
            endDateTime={endDateTime}
            onClick={openObjectInformation}
            onSlideChange={handleOnSlideChange}
            rooms={rooms}
            startDateTime={startDateTime}
            exploreState={exploreState}
            ref={ref}
            areas={areas}
          />
        </Div>
        <RoomListGridArea
          gridArea="room-list"
          overflow="hidden"
          zIndex={2}
          height="calc(var(--100vh, 100vh) - 60px)"
          $roomListVerticalOpen={roomListVerticalOpen}>
          <Div
            position="relative"
            top="0"
            zIndex={1}
            left="0">
            {displayCalendarSyncTile && (
              <Div sm={{display: 'grid', gridArea: 'calendar-sync-tile', zIndex: 1}}>
                <HaNCalendarSyncTile />
              </Div>
            )}
            <Div
              sm={{display: 'none'}}
              zIndex={10}
              gridArea="title">
              <Title>
                <H3 as="h1">{buildingName}</H3>
                {allBuildings.length > 1 ? (
                  <IconButton
                    aria-label="building-select"
                    icon="caretDown"
                    iconButton="tertiary"
                    onClick={handleBuildingSelectClick}
                    size="small"
                  />
                ) : null}
              </Title>
            </Div>
            {tab !== 'explore' ? (
              <StyledTabs
                gridArea="tabs"
                zIndex={10}>
                {isHybridMeetingsCalendarViewEnabled || isWorkspacesOnHereAndNowEnabled ? (
                  <StyledNavLink
                    $isActive={tab === 'rooms'}
                    onClick={() => {
                      resetEntityState('rooms');
                      refreshRoomsData();
                      sessionStorage.setItem(HaN_Previous_Tab, '/now/rooms');
                    }}
                    to={'/now/rooms'}>
                    {t('hereAndNow:Rooms')}
                  </StyledNavLink>
                ) : null}
                {isWorkspacesOnHereAndNowEnabled ? (
                  <StyledNavLink
                    $isActive={tab === 'workspaces'}
                    onClick={() => {
                      resetEntityState('workspaces');
                      sessionStorage.setItem(HaN_Previous_Tab, '/now/workspaces');
                    }}
                    to={'/now/workspaces'}>
                    {t('hereAndNow:Workspaces')}
                  </StyledNavLink>
                ) : null}
                <StyledNavLinkWithIcon to={'/now/explore'}>
                  <StyledIconNav
                    onClick={() => resetEntityState()}
                    weight="bold"
                    icon="search"
                    size="20px"
                  />
                </StyledNavLinkWithIcon>
              </StyledTabs>
            ) : null}
          </Div>

          {tab === 'explore' && (
            <FlexRow
              // @ts-expect-error
              key={roomListVerticalOpen} // force rerender so values between 2 search fields are in sync
              height="var(--tabs-height)"
              justifyContent="center"
              alignItems="center">
              <HaNExploreSearchBar
                exploreState={exploreState}
                onBack={handleOnBackExplore}
                showBackButton={!!(isWorkspacesOnHereAndNowEnabled || isHybridMeetingsCalendarViewEnabled)}
              />
            </FlexRow>
          )}

          <Div
            display="none"
            sm={{display: 'block', padding: '16px 20px'}}>
            <HaNFilters
              swiperFilterReset={resetListState}
              areFilterTransparent={areFiltersTransparent}
            />
          </Div>
          <FlexCol
            sm={{display: !roomListVerticalOpen ? 'none' : 'flex'}}
            overflow="hidden"
            flex="auto"
            zIndex={2}>
            {showCalendarConsentMessageNoOccupancy ? (
              <HaNCalendarSync />
            ) : (
              <HaNVerticalListContent
                endDateTime={endDateTime}
                onClick={openObjectInformation}
                onHover={setHoverRoomId}
                rooms={rooms}
                areas={areas}
                exploreState={exploreState}
                selectedObject={selectedObject}
                startDateTime={startDateTime}
              />
            )}
          </FlexCol>
        </RoomListGridArea>
      </HereAndNowLayout>

      <HaNModalContainer
        setWarnBeforeOpeningNewRoom={setWarnBeforeOpeningNewRoom}
        onClose={closeDetailsModal}
        entity={selectedObject}
        show={roomInformationOpen}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        duration={duration}
        onBookNowCallback={handleBookNow}
      />
    </>
  );
};
