import {IAuthManager} from '@lib/infrastructure';
import {
  AccountInfo,
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import {RouteValues} from '@constants';

export const authManager = (instance: PublicClientApplication): IAuthManager => {
  const scopes: string = import.meta.env.MAPIQ_AUTH_LOGIN_SCOPES ?? '';

  const fromScopesString = (str: string) => str.split(',').map((scope: string) => scope.trim());

  const getLoginHint = (account: AccountInfo | null) => {
    if (!account) return;

    const claims = account.idTokenClaims;

    if (!claims) {
      return;
    }

    // As we don't have the login_hint claim, we need to provide our custom login hint
    const loginHint = claims['sign_in_name'] || claims['email'];
    return loginHint as string;
  };

  const loginRequest = (loginHint?: string, prompt = false): RedirectRequest => {
    return {
      scopes: fromScopesString(scopes),
      loginHint: loginHint,
      redirectUri: `${window.location.origin}/${RouteValues.loginRedirect}`,
      prompt: prompt ? 'login' : undefined,
    };
  };

  const silentRequest = (): SilentRequest => {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
    return {
      scopes: fromScopesString(scopes),
      forceRefresh: false,
      redirectUri: window.location.origin + `/silent-login`,
    };
  };

  const logoutRequest = (logoutHint?: string): EndSessionRequest => {
    return {
      logoutHint: logoutHint,
      // Since the auth server doesn't accept loging-in from any route, it's convenient
      // to set this to be the origin, so that on the next attempt it will be
      // possible to login directly
      postLogoutRedirectUri: window.location.origin,
    };
  };

  return {
    signInRedirect: async () => {
      await instance.loginRedirect(loginRequest());
    },
    signOut: async () => {
      const account = instance.getActiveAccount();
      const logoutHint = getLoginHint(account);
      await instance.logoutRedirect(logoutRequest(logoutHint));
    },
    getAccessToken: async () => {
      const account = instance.getActiveAccount();

      if (!account) return;

      try {
        const silentResponse = await instance.acquireTokenSilent(silentRequest());

        // If we get here, there is no reason to believe we cannot request an automatic
        // redirect next time we need an access token
        clearRedirectAttemptWithLoginHint();

        return silentResponse.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            // Use whatever we know about our user to create a useful set of options:
            // login hint automatically redirects to the SSO page if recognized
            const loginHint = getLoginHint(account);

            // If we have reason to believe the user is struggling to sign in to the
            // account stored in login-hint, we will force the B2C to prompt them to
            // confirm their email
            const shouldPrompt = hasPendingRedirectAttemptWithLoginHint();

            if (loginHint) {
              registerRedirectAttemptWithLoginHint();
            }

            const requestOptions = loginRequest(loginHint, shouldPrompt);
            await instance.acquireTokenRedirect(requestOptions);
            return;
          } catch {
            // REMARK: For now we don't do anything.
            return;
          }
        } else {
          console.error(error);
        }
      }
    },
  };
};

const registerRedirectAttemptWithLoginHint = () => {
  localStorage.setItem('pending_token_redirect', '1');
};

const clearRedirectAttemptWithLoginHint = () => {
  localStorage.removeItem('pending_token_redirect');
};

const hasPendingRedirectAttemptWithLoginHint = () => {
  return localStorage.getItem('pending_token_redirect') !== null;
};
